import React from 'react';

import CaptureModeProvider from 'plantiga-common/CaptureMode/CaptureModeProvider';
import { MetricDefnsProvider } from 'plantiga-common/Fields';
import { TimeseriesDefnsProvider } from 'plantiga-common/Fields/TimeseriesDefnsProvider';
import FullWidthPageProvider from 'plantiga-common/FullWidthPage/FullWidthPageProvider';
import StackProvider from 'plantiga-common/StackProvider';
import Nav from 'plantiga-component/Nav/Nav';
import NotificationsProvider from 'plantiga-component/Nav/Notifications/NotificationsProvider';
import ActivityTypesProvider from 'plantiga-firebase/ActivityTypes/ActivityTypesProvider';
import AthletesProvider from 'plantiga-firebase/Athletes/AthletesProvider';
import ConnectedProvider from 'plantiga-firebase/Connected/ConnectedProvider';
import DevicesProvider from 'plantiga-firebase/Devices/DevicesProvider';
import FeatureFlagsProvider from 'plantiga-firebase/FeatureFlags/FeatureFlagsProvider';
import { WaitForFeatureFlags } from 'plantiga-firebase/FeatureFlags/WaitForFeatureFlags';
import TeamProvider from 'plantiga-firebase/Team/TeamProvider';

type Props = {
  children: React.ReactNode;
};

export default function TeamLayout({ children }: Props) {
  return (
    <TeamProvider>
      <ConnectedProvider>
        <FeatureFlagsProvider>
          <AthletesProvider>
            <DevicesProvider>
              <ActivityTypesProvider>
                <MetricDefnsProvider>
                  <TimeseriesDefnsProvider>
                    <WaitForFeatureFlags>
                      <NotificationsProvider>
                        <StackProvider>
                          <FullWidthPageProvider>
                            <CaptureModeProvider>
                              <Nav>{children}</Nav>
                            </CaptureModeProvider>
                          </FullWidthPageProvider>
                        </StackProvider>
                      </NotificationsProvider>
                    </WaitForFeatureFlags>
                  </TimeseriesDefnsProvider>
                </MetricDefnsProvider>
              </ActivityTypesProvider>
            </DevicesProvider>
          </AthletesProvider>
        </FeatureFlagsProvider>
      </ConnectedProvider>
    </TeamProvider>
  );
}
