import React from 'react';

import { noop } from 'lodash-es';

import { useTheme } from '@mui/material';

type ContextType = [boolean, () => void];

export const Context: React.Context<ContextType> = React.createContext<ContextType>([false, noop]);

type Props = {
  readonly children: any;
};

export default function CaptureModeProvider({ children }: Props) {
  const theme = useTheme();
  const [captureMode, setCaptureMode] = React.useState(false);
  const toggle = React.useCallback(() => {
    setCaptureMode((p) => !p);
  }, [setCaptureMode]);
  const value = React.useMemo(() => [captureMode, toggle] as ContextType, [captureMode, toggle]);

  return (
    <Context.Provider value={value}>
      {captureMode && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            backgroundColor: theme.palette.background.default,
            opacity: 0.8,
            // under app bar and under capture wrapper elements
            zIndex: theme.zIndex.appBar - 2,
          }}
        />
      )}
      {children}
    </Context.Provider>
  );
}
