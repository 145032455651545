import { getDocs } from 'firebase/firestore';
import React from 'react';

import makeDebug from 'debug';
import { fromPairs, mergeWith, isEmpty, mapValues } from 'lodash-es';
import { useLocalStorage } from 'usehooks-ts';

import { collection } from '..';
import { useCurrentTeam } from '../Team/useTeam';
import useFirestore from '../useFirestore';

import { flagDescriptions } from './constants';

const debug = makeDebug('plantiga:feature_flag');

export type FeatureFlags = {
  readonly [key: string]: boolean;
};

type FFType = {
  readonly featureFlags: FeatureFlags;
  readonly loading: boolean;
  readonly initialized: boolean;
  readonly error: Error | null | undefined;
};

const FeatureFlagsContext: React.Context<FFType> = React.createContext<FFType>({
  featureFlags: {},
  loading: false,
  initialized: false,
  error: null,
});

export function makeFeatureFlags(
  priorityDefault?: FeatureFlags | null,
  db?: FeatureFlags | null,
  team?: FeatureFlags | null,
): FeatureFlags {
  return mergeWith(
    {},
    priorityDefault,
    db,
    team,
    (left: boolean, right: boolean): boolean => left || right,
  );
}

export function useFeatureFlagContext() {
  const [defaultEnabled] = useLocalStorage<boolean | undefined>('featureFlagsDefault', undefined);
  const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlags>('featureFlags', {});
  const [initialized, setInitialized] = React.useState(!isEmpty(featureFlags));
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null | undefined>(null);

  const { data: team } = useCurrentTeam();
  const db = useFirestore();

  const teamFeatureFlags = React.useMemo(() => (team && team.feature_flags) || {}, [team]);
  const siteFeatureFlags = React.useMemo(() => window.plantiga.featureFlags || {}, []);

  React.useEffect(() => {
    setLoading(true);
    setError(null);

    getDocs(collection(db, 'feature_flags'))
      .then((ffSnap) => {
        const dbFeatureFlags = fromPairs(ffSnap.docs.map((s) => [s.id, s.data().enabled]));
        const defaultValue = defaultEnabled ?? siteFeatureFlags.default ?? false;

        const priorityDefault = {
          ...mapValues(flagDescriptions, () => defaultValue),
          ...siteFeatureFlags,
          ...{ default: defaultValue },
        };

        const ff = makeFeatureFlags(priorityDefault, dbFeatureFlags, teamFeatureFlags);
        debug('Loaded FeatureFlags', ff);
        setFeatureFlags(ff);
        setLoading(false);
        setInitialized(true);
      })

      .catch((err) => {
        console.error(err);
        setLoading(false);
        setInitialized(true);
        setError(err);
      });
  }, [db, siteFeatureFlags, teamFeatureFlags, setFeatureFlags, defaultEnabled]);
  return { featureFlags, initialized, loading, error };
}

export default FeatureFlagsContext;
