import React from 'react';

import { difference, memoize } from 'lodash-es';

import { getTeamPreferences } from 'plantiga-firebase/Team/preferences';
import { useCurrentTeam } from 'plantiga-firebase/Team/useTeam';
import { findNestedPaths } from 'plantiga-util/urls';

const BASIC_TIER = ['athleteLoadOverview', 'athleteDaily'] as const;
const ADVANCED_TIER = [
  'record',
  'recordActive',
  'recordFinalize',
  'stopwatch',
  'stopwatchRunning',
  'stopwatchFinalize',
  'athleteTimeline',
  'activityReport',
  'athleteProfileLabels',
  'athleteProfileEvents',
  'athleteProfileFiles',
] as const;
const EXPERT_TIER = [
  'teamExport',
  'activityExplore',
  'athleteActivityExplore',
  'athleteActivityLabels',
  'athleteActivityFiles',
  'activityFiles',
  'teamActivityTypes',
  'activityLabels',
  'teamTimeline',
] as const;
const ADMIN_TIER = ['activityStatsForNerds', 'athleteActivitySummaries'] as const;
const TIERS = [BASIC_TIER, ADVANCED_TIER, EXPERT_TIER, ADMIN_TIER];
const ALL_TIER_VIEWS = new Set(TIERS.flatMap((v) => v));

type ExtractArrayValues<T> = T extends readonly (infer U)[] ? U : never;
type ExtractNestedArrayValues<T> = T extends readonly (infer U)[] ? ExtractArrayValues<U> : never;

export type TierView = ExtractNestedArrayValues<typeof TIERS>;

/**
 * Type guard to check if the view (pathname) is explicitly designated in a tier
 */
export const isTierView = (view: string): view is TierView => ALL_TIER_VIEWS.has(view as TierView);

const excludedViews = memoize((tier: number) => new Set(TIERS.slice(tier).flatMap((v) => v)));
const includedViews = memoize((tier: number) => {
  // include these views and all their nested views
  const nestedViews = TIERS.slice(0, tier)
    .flatMap((v) => v)
    .flatMap((v) => findNestedPaths(v));
  // if a nested view is explicitly in a higher tier, exclude it
  const withNestedViews = difference(nestedViews, [...excludedViews(tier)]);
  return new Set(withNestedViews);
});

export function useTier() {
  const { data: team } = useCurrentTeam();
  const { display } = getTeamPreferences(team);
  // if the team tier is not set, this team predates site-tiers
  // and should default to the maximum tier
  const MAX_TIER = 3;
  return Math.min(team?.tier ?? MAX_TIER, display.tier ?? MAX_TIER);
}

/**
 * @returns Every view (pathname) explicitly accessible via a tier
 */
export function useTierViews() {
  const tier = useTier();
  return React.useMemo(
    () => ({
      include: includedViews(tier),
      exclude: excludedViews(tier),
    }),
    [tier],
  );
}
