import { updateDoc } from 'firebase/firestore';
import { useState, useEffect, useCallback, useContext } from 'react';

import makeDebug from 'debug';
import { set, cloneDeep } from 'lodash-es';
import useSWR from 'swr';

import useCoach from 'plantiga-firebase/Coach/useCoach';

import { doc } from '..';
import { useToast } from '../../Toast/UseToast';
import type { Team } from '../schema';
import useFirestore from '../useFirestore';

import { getTeam, getTeams } from './helpers';
import TeamContext from './TeamContext';

const debug = makeDebug('plantiga:team');

type PrefKey = 'measures' | 'name' | 'display.injury_history' | 'display.tier';

export default function useTeam(): {
  teamId: string;
} {
  const { teamId: currentTeamId } = useContext(TeamContext);

  return {
    teamId: currentTeamId || '',
  };
}

export function useCurrentTeam() {
  const { teamId } = useTeam();
  const db = useFirestore();
  return useSWR([getTeam, teamId], ([rpc, arg]) => rpc(db, arg));
}

export function useUpdatePreferences() {
  const postToast = useToast();
  const db = useFirestore();
  const { teamId } = useTeam();
  const { data: team, mutate } = useCurrentTeam();

  return useCallback(
    async (key: PrefKey, value: any) => {
      if (teamId == null || team == null) return;

      const updatedTeam = cloneDeep(team);
      set(updatedTeam, key, value);
      mutate(
        async () => {
          try {
            const ref = doc(db, 'teams', teamId);
            await updateDoc(ref, { [key]: value });
            return undefined;
          } catch (err: any) {
            console.error(err);
            postToast({
              message: 'Failed to update organization',
              variant: 'error',
            });
            throw err;
          }
        },
        { optimisticData: updatedTeam },
      );
    },
    [teamId, db, postToast, mutate, team],
  );
}

export const useLoadTeams = () => {
  const [teams, setTeams] = useState<{ [teamId: string]: Team }>({});
  const [error, setError] = useState<Error | null | undefined>(null);
  const [loading, setLoading] = useState(true);
  const { coach } = useCoach();

  const db = useFirestore();
  useEffect(() => {
    let canceled = false;
    const doEffect = async () => {
      if (canceled) return;
      setLoading(true);

      try {
        debug('Authorized Teams by Coach:', coach?.teams);
        const loadedTeams = await getTeams(db, coach);
        debug('LoadedTeams:', loadedTeams);
        if (canceled) return;
        setTeams(loadedTeams);
      } catch (err: any) {
        console.warn(err);
        if (canceled) return;
        setError(err);
        setLoading(false);
        return;
      }

      if (canceled) return;
      setLoading(false);
    };
    doEffect();
    return () => {
      canceled = true;
    };
  }, [coach, db]);

  return {
    teams,
    loading,
    error,
  };
};
