import React from 'react';
import { Router } from 'react-router-dom';

import type { History } from 'history';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from 'tss-react/mui';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { SwContextProvider } from 'plantiga-common/ServiceWorker/SwContext';
import ErrorBoundary from 'plantiga-component/Error/Boundary';
import Splash from 'plantiga-component/Firestore/Auth/Splash';
import FirebaseAnalytics from 'plantiga-component/Instrumentation/FirebaseAnalytics';
import { usePlantigaTheme } from 'plantiga-component/theme';

const useStyles = makeStyles()((theme) => ({
  appBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

function AppWithTheme({ history, children }: AppProps) {
  const { classes } = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.appBackground}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router history={history}>
            <SnackbarProvider maxSnack={3}>
              <>
                <ErrorBoundary topLevel>{children}</ErrorBoundary>
                <FirebaseAnalytics />
              </>
            </SnackbarProvider>
          </Router>
        </LocalizationProvider>
      </div>
    </>
  );
}

type AppProps = {
  history: History; // TODO: RouterHistory is not compatible with 'history' types,
  children: React.ReactNode;
};

function App(props: AppProps) {
  const theme = usePlantigaTheme();

  return (
    <>
      <Splash />
      <SwContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppWithTheme {...props} />
          </ThemeProvider>
        </StyledEngineProvider>
      </SwContextProvider>
    </>
  );
}

export default App;
