import { getAnalytics, setUserProperties } from 'firebase/analytics';
import React from 'react';

import { getCurrentScope } from '@sentry/react';

export default function useTeamId(teamId: string) {
  React.useEffect(() => {
    getCurrentScope().setTag('teamId', teamId);
    setUserProperties(getAnalytics(), { team_id: teamId });
  }, [teamId]);
}
