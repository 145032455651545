import React from 'react';

import { mapValues, merge, pickBy } from 'lodash-es';
import useSWR from 'swr';

import type { MetricDefns } from '@plantiga/activity-summaries';

import useFeatureFlag from 'plantiga-firebase/FeatureFlags/useFeatureFlag';
import useTeam from 'plantiga-firebase/Team/useTeam';
import _fields from 'plantiga-util/fields';
import { useLocalCache } from 'plantiga-util/useLocalCache';

import useActivitySummariesClient from '../useActivitySummariesClient';

import Context from './FieldsContext';
import _jumpHeightAsAirtime from './jump_height_as_airtime.yml';

const jumpHeightAsAirtime = _jumpHeightAsAirtime as { [field_id: string]: string };

type Props = {
  readonly children: React.ReactNode;
};

const toggleJumpHeightsAsAirtime = <T,>(defns: { [key: string]: T }) =>
  mapValues(defns, (defn, id) => defns[jumpHeightAsAirtime[id]] ?? defn);

export function MetricDefnsProvider({ children }: Props) {
  const { teamId } = useTeam();
  const client = useActivitySummariesClient();
  const [localMetricDefns, setLocalMetricDefns] = useLocalCache<{
    teamId: string;
    data: MetricDefns | undefined;
  }>('metric-defns', true);
  const jumpHeightAsAirtimeFF = useFeatureFlag('jump_height_as_airtime');

  const fetcher = async ([func, orgId]: [typeof client.getOrgMetricDefinitions, string]) => {
    const { metric_definitions: defns } = await func(orgId);
    return defns;
  };
  const swrRes = useSWR([client.getOrgMetricDefinitions, teamId], fetcher, {
    // definitions only need to be fetched once
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: localMetricDefns?.teamId === teamId ? localMetricDefns.data : undefined,
  });

  React.useEffect(() => {
    setLocalMetricDefns({ teamId, data: swrRes.data });
  }, [swrRes.data, setLocalMetricDefns, teamId]);

  const value = React.useMemo(() => {
    const fields = mapValues(
      // use fields.yml as the source of truth for metrics defined there.
      merge({}, _fields, pickBy(swrRes.data, (_, k) => k.includes(':')) ?? {}),
      // add the metric-id as a property to each definition
      (v, id) => ({ ...v, id }),
    );
    return {
      loading: swrRes.isLoading,
      error: swrRes.error,
      fields: jumpHeightAsAirtimeFF ? toggleJumpHeightsAsAirtime(fields) : fields,
      mutate: swrRes.mutate,
    } as const;
  }, [swrRes, jumpHeightAsAirtimeFF]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
