import React from 'react';

import { findKey, omitBy } from 'lodash-es';

import { MISSING_FIELD_DEFINITION } from './constants';
import Context from './FieldsContext';
import type { FieldDefinition, Fields } from './typedefs';

export default function useFields(options?: {
  pending?: boolean;
  deprecated?: boolean;
  all?: boolean;
}): {
  fields: Fields;
  getField: (fieldId: string) => FieldDefinition;
} {
  const { fields } = React.useContext(Context);

  const omitCallback = React.useCallback(
    (v: FieldDefinition | undefined) => {
      if (v === undefined) return true;
      if (options?.all) return false;
      if (v.name === undefined) return true;
      if (v.status === 'deprecated' && options?.deprecated) return false;
      if (v.status === 'deprecated') return true;
      if (options?.pending) return false;
      return v.status === 'pending';
    },
    [options?.all, options?.pending, options?.deprecated],
  );

  const defns = React.useMemo(() => omitBy(fields, omitCallback), [fields, omitCallback]);

  /** Safe method to lookup a field definition regardless of it's existence
   *
   * `undefined` field definitions return the `MISSING_FIELD_DEFINITION` global constant
   */
  const getField = React.useCallback(
    (fieldId: string) => {
      if (fieldId in defns) return defns[fieldId];
      return MISSING_FIELD_DEFINITION;
    },
    [defns],
  );

  return { fields: defns, getField };
}

export function useField(fieldKey: string): FieldDefinition {
  const { fields } = React.useContext(Context);
  return fields[fieldKey] ?? MISSING_FIELD_DEFINITION;
}

export function useFieldsLoading() {
  const { loading, error } = React.useContext(Context);
  return { loading, error };
}

export function useUpdateMetricDefns() {
  const { mutate } = React.useContext(Context);
  return mutate;
}

/**
 * Filenames may have a `${service}:` prefix. This prefix is not mentioned in
 * the fields.timeseries definition, so it needs to be stripped before we search
 * for the corresponding field key.
 *
 * @param file the name of the timeseries file
 */
export function stripPrefix(file: string) {
  const idx = file.indexOf(':');
  // If ":" is missing, this will return the whole string, otherwise it will
  // remove up to and including the ":".
  return file.slice(idx + 1);
}

export function getPrefix(file: string) {
  const idx = file.indexOf(':');
  // If ":" is missing, this will return an empty string, otherwise it will
  // return up to and including the ":".
  return file.slice(0, idx + 1);
}

export function useFieldKeyByTimeseries(
  file: string,
  field: string,
  aggregation: string,
  chirality: 'left' | 'right' | 'asymmetry' | 'achiral',
): string | undefined {
  const { fields } = React.useContext(Context);
  return React.useMemo(
    () =>
      findKey(fields, { timeseries: { file: stripPrefix(file), field, aggregation, chirality } }),
    [fields, file, field, aggregation, chirality],
  );
}
