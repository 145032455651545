import React from 'react';
import { useParams } from 'react-router-dom';

import useTeamId from 'plantiga-component/Instrumentation/useTeamId';
import RedirectToTeam from 'plantiga-component/Welcome/RedirectToTeam';

import useCoach from '../Coach/useCoach';

import TeamContext from './TeamContext';

type Props = {
  children: React.ReactNode;
  teamId?: string;
};

export default function TeamProvider({ teamId: specificTeamId, children }: Props) {
  const { teamId: urlTeamId } = useParams<{ teamId: string }>();
  const teamId = specificTeamId ?? urlTeamId;
  const { teams } = useCoach();
  const context = React.useMemo(() => ({ teamId }), [teamId]);

  useTeamId(teamId);

  if (teams != null && !teams.includes(teamId)) {
    return <RedirectToTeam permissionDenied />;
  }

  return <TeamContext.Provider value={context}>{children}</TeamContext.Provider>;
}
