import React from 'react';

import { formatISO } from 'date-fns';
import { toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { useNavigateURL } from 'plantiga-common/useBuildURL';
import Wait from 'plantiga-common/Wait';
import EventCard from 'plantiga-component/Athletes/Profile/Events/EventCard';
import useEvents from 'plantiga-firebase/Events/useEvents';
import { getTeamPreferences } from 'plantiga-firebase/Team/preferences';
import { useCurrentTeam } from 'plantiga-firebase/Team/useTeam';

const useStyles = makeStyles()((theme) => ({
  eventsContainer: {
    maxHeight: 240,
    overflowY: 'scroll',
  },
  icon: {
    transition: `transform ${theme.transitions.duration.short}ms`,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  card: {
    background: theme.palette.background.default,
  },
}));

type Props = {
  readonly athleteId: string;
  readonly gutterBottom?: boolean;
};

function InjuriesCard({ athleteId, gutterBottom = false }: Props) {
  const { classes, cx } = useStyles();
  const navigateURL = useNavigateURL();
  const { events, loading, error } = useEvents(athleteId);
  const [selected, setSelected] = React.useState<any>(null);
  const [expand, setExpand] = React.useState(false);

  const sortedEvents = React.useMemo(
    () => toPairs(events).filter(([, { event_type: t }]) => ['injury', 'surgery'].includes(t)),
    [events],
  );

  const cardClassName = cx(classes.card, { [classes.gutterBottom]: gutterBottom });
  return (
    <Wait
      waitingOn={loading}
      error={error}
      info="recent-events"
      ProgressNode={
        <Card variant="outlined" className={cardClassName}>
          <List disablePadding>
            <ListItem dense>
              <Skeleton>
                <ListItemText primary="primary loader text" secondary="secondary loader text" />
              </Skeleton>
            </ListItem>
          </List>
        </Card>
      }
    >
      <Card variant="outlined" className={cardClassName}>
        <div className={classes.eventsContainer}>
          <List disablePadding>
            {sortedEvents.length > 0 ? (
              <ListItem dense button onClick={() => setSelected(sortedEvents[0][0])}>
                <ListItemText
                  primary={sortedEvents[0][1].title}
                  secondary={formatISO(sortedEvents[0][1].date.toDate(), {
                    representation: 'date',
                  })}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="show more">
                    <IconButton onClick={() => setExpand((p) => !p)} size="small">
                      <ExpandMoreIcon className={cx(classes.icon, { [classes.rotate]: expand })} />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ) : (
              <ListItem dense>
                <ListItemText
                  primary="No injuries reported"
                  secondary={`Injury and Surgery events can be added from the "Profile/Events" page`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    onClick={() => navigateURL('athleteProfileEvents', { athleteId })}
                  >
                    Events
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            <Collapse in={expand}>
              {sortedEvents.slice(1).map(([eventId, { title, date }]) => (
                <ListItem dense button key={eventId} onClick={() => setSelected(eventId)}>
                  <ListItemText
                    primary={title}
                    secondary={formatISO(date.toDate(), { representation: 'date' })}
                  />
                </ListItem>
              ))}
            </Collapse>
          </List>
        </div>
        <Dialog fullWidth open={!!selected} onClose={() => setSelected(null)}>
          {selected ? (
            <EventCard athleteId={athleteId} eventId={selected} event={events[selected]} />
          ) : null}
        </Dialog>
      </Card>
    </Wait>
  );
}

export default function Guard(props: Props) {
  const { data: team } = useCurrentTeam();
  const { display } = getTeamPreferences(team);
  if (!display.injury_history) return null;
  return <InjuriesCard {...props} />;
}
