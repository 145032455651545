import { useAthlete } from '../Firestore/Athletes/useAthletes';
import { useCurrentTeam } from '../Firestore/Team/useTeam';

import { useDocumentTitle } from './hooks';

export function useTeamTitle(suffix?: string | null) {
  const { data: team } = useCurrentTeam();

  let title = team && team.name ? team.name : '';
  if (suffix != null) {
    title += ` • ${suffix}`;
  }
  useDocumentTitle(title);
}

export function useAthleteTitle(athleteId: string, suffix?: string | null) {
  const athlete = useAthlete(athleteId);

  let title = athlete ? athlete.name : '?';
  if (suffix != null) {
    title += ` • ${suffix}`;
  }
  useDocumentTitle(title);
}
