import React from 'react';

import type { Coach } from '../schema';

export type CoachContextType = {
  coach: Coach | null | undefined;
  teams: string[] | undefined;
  loading: boolean;
  error: Error | null | undefined;
};

const CoachContext: React.Context<CoachContextType> = React.createContext<CoachContextType>({
  coach: undefined,
  teams: undefined,
  loading: false,
  error: null,
});

export default CoachContext;
