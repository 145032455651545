import React from 'react';

import { Context } from './CaptureModeProvider';

export function useCaptureMode() {
  return React.useContext(Context)[0];
}

export function useToggleCaptureMode() {
  return React.useContext(Context)[1];
}
